import thehindu from "./images/The_hindu.png";
import easy_siksha from "./images/easy_siksha.png";
import edu_times from "./images/education_times.png";
import high_edu_dig from "./images/Higher_edu_digest.png";
import your_story from "./images/your_story.png";

const blogs = [
  // {
  //   id: "News from the world of education - August 6, 2021",
  //   type: "post",
  //   thumbnail: thehindu,
  //   title: "Kraftshala launches online digital marketing course",
  //   about: "Kraftshala is all set to launch ‘The Marketing Launchpad’,...",
  //   link:
  //     "https://www.thehindu.com/education/information-on-admissions-new-courses-scholarships-mous-partnerships-and-more/article35758777.ece",
  // },
  // {
  //   id: "Kraftshala Marketing Launchpad program",
  //   type: "post",
  //   thumbnail: bw_edu,
  //   title: "Kraftshala Marketing Launchpad program.",
  //   about:
  //     "The industry-driven program, where you pay the fee after landing a high potential ...",
  //   link:
  //     "http://bweducation.businessworld.in/article/Kraftshala-Marketing-Launchpad-program/09-08-2021-400073/",
  // },
  // {
  //   id: "Jet-set into the world of marketing with Kraftshala’s Marketing Launchpad program",
  //   type: "post",
  //   thumbnail: high_edu_dig,
  //   title: "Jet-set into the world of marketing with Kraftshala’s Marketing Launchpad program.",
  //   about: "Kraftshala, an ed-tech organization that ... ",
  //   link:
  // 		"https://www.highereducationdigest.com/jet-set-into-the-world-of-marketing-with-kraftshalas-marketing-launchpad-program/",
  // },

  // {
  //   id:
  //     "This marketing course by Kraftshala allows students to pay fees only after they have landed a high-potential job",
  //   type: "post",
  //   thumbnail: marketech,
  //   title:
  //     "This marketing course by Kraftshala allows students to pay fees only after they have landed a high-potential job",
  //   about: "",
  //   link:
  //     "https://marketech-apac.com/this-marketing-course-by-kraftshala-allows-students-to-pay-fee-only-after-they-have-landed-a-high-potential-job/",
  // },

  {
    id:
      "How Marketing as a Subject Has Evolved Over the Years Gaining Momentum as It Progresses",
    type: "post",
    thumbnail: high_edu_dig,
    title:
      "How Marketing as a Subject Has Evolved Over the Years Gaining Momentum as It Progresses",
    about: "The Marketing Models and how marketing ...",
    link:
      "https://www.highereducationdigest.com/how-marketing-as-a-subject-has-evolved-over-the-years-gaining-momentum-as-it-progresses/",
  },

  {
    id: "Importance of Marketing in executive education",
    type: "post",
    thumbnail: edu_times,
    title: "Importance of Marketing in executive education",
    about:
      "To identify the right growth opportunity, understand a brand’s awareness levels ... ",
    link:
      "https://www.educationtimes.com/article/editors-pick/85682491/importance-of-marketing-in-executive-education",
  },

  // {
  //   id: "Kraftshala’s Sessions On ‘Cracking Summer Placements’ Now Open To All",
  //   type: "post",
  //   thumbnail: bw_edu,
  //   title:
  //     "Kraftshala’s Sessions On ‘Cracking Summer Placements’ Now Open To All",
  //   about: "Kraftshala’s CEO takes the steps ...",
  //   link:
  //     "http://bweducation.businessworld.in/article/Kraftshala-s-Sessions-On-Cracking-Summer-Placements-Now-Open-To-All-/28-09-2021-406391/",
  // },

  // {
  //   id:
  // 		"One of Kraftshala’s coveted sessions on ‘Cracking Summer Placements’ made free and open to all",
  //   type: "post",
  //   thumbnail: high_edu_dig,
  //   title:
  // 		"One of Kraftshala’s coveted sessions on ‘Cracking Summer Placements’ made free and open to all",
  //   about: "",
  //   link:
  // 		"https://www.highereducationdigest.com/one-of-kraftshalas-coveted-sessions-on-cracking-summer-placements-made-free-and-open-to-all/",
  // },

  {
    id:
      "One of Kraftshala ’s coveted sessions on ‘Cracking Summer Placements’ made free and open to all",
    type: "post",
    thumbnail: easy_siksha,
    title:
      "One of Kraftshala ’s coveted sessions on ‘Cracking Summer Placements’ made free and open to all",
    about: "",
    link:
      "https://news.easyshiksha.com/one-of-kraftshala-s-coveted-sessions-on-cracking-summer-placements-made-free-and-open-to-all/",
  },

  // {
  //   id: "News from the world of Education: October 2, 2021",
  //   type: "post",
  //   thumbnail: thehindu,
  //   title:
  //     "Kraftshala 's New Course on content creation and social media management",
  //   about:
  //     "Kraftshala has launched a new specialisation in ‘Content & Social Media... ",
  //   link:
  //     "https://www.thehindu.com/education/information-on-admissions-courses-scholarships-and-more/article37879351.ece",
  // },

  {
    id: "How consumer-driven marketing can increase reach",
    type: "post",
    thumbnail: your_story,
    title: "How consumer-driven marketing can increase reach",
    about:
      "Convergence marketing ensures that brands use every tool at their disposal ...",
    link:
      "https://yourstory.com/2021/08/consumer-driven-marketing-increase-reach/amp",
  },

  // {
  //   id:
  //     "Kraftshala expands its marketing launchpad programmes, adds new course in digital media",
  //   type: "post",
  //   thumbnail: edu_graph,
  //   title:
  //     "Kraftshala expands its marketing launchpad programmes, adds new course in digital media",
  //   about: "This programme will help candidates ... ",
  //   link:
  //     "https://www.telegraphindia.com/edugraph/news/headline-kraftshala-expands-its-marketing-launchpad-programmes-adds-new-course-in-digital-media/cid/1841718",
  // },

  // {
  //   id:
  //     "Kraftshala expands its MLP program with the addition of its ‘Content and Social Media Marketing’ specialization",
  //   type: "post",
  //   thumbnail: easy_siksha,
  //   title:
  //     "Kraftshala expands its MLP program with the addition of its ‘Content and Social Media Marketing’ specialization",
  //   about: "",
  //   link:
  //     "https://news.easyshiksha.com/kraftshala-expands-its-mlp-program-with-the-addition-of-its-content-and-social-media-marketing-specialization/",
  // },

  // {
  //   id:
  // 		"Kraftshala expands its MLP program with the addition of its ‘Content and Social Media Marketing’ specialization",
  //   type: "post",
  //   thumbnail: high_edu_dig,
  //   title:
  // 		"Kraftshala expands its MLP program with the addition of its ‘Content and Social Media Marketing’ specialization",
  //   about: "",
  //   link:
  // 		"https://www.highereducationdigest.com/kraftshala-expands-its-mlp-program-with-the-addition-of-its-content-and-social-media-marketing-specialization/",
  // },

  {
    id: "News from the world of education - December 7, 2021",
    type: "post",
    thumbnail: thehindu,
    title: "Kraftshala LIVE sessions",
    about:
      "Kraftshala recently announced its fourth annual Kraftshala LIVE sessions ...",
    link:
      "https://www.thehindu.com/education/information-on-admissions-courses-scholarships-and-more/article36790485.ece",
  },

  // {
  //   id: "KRAFTSHALA EXPANDS MARKETING LAUNCH PROGRAMS, ADDS NEW DIGITAL MEDIA COURSE",
  //   type: "post",
  //   thumbnail: oz,
  //   title: "KRAFTSHALA EXPANDS MARKETING LAUNCH PROGRAMS, ADDS NEW DIGITAL MEDIA COURSE",
  //   about: "",
  //   link:
  // 		"https://www.ozwebcrew.net/kraftshala-expands-marketing-launch-programs-adds-new-digital-media-course/",
  // },
];

// total blogs 36
// 25 on page one
// rest on page two
const pageOne = blogs.slice(0, 25);
//const pageTwo = blogs.slice(25, blogs.length);

export { pageOne };
