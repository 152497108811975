import React from "react";
import { Link } from "gatsby";
import PressRelease from "../../components/PressReleases/index";
import Layout from "../../components/Layout/layout";
import Seo from "../../components/Seo/seo";

import {
  courseSnipptJSON,
  faqSnipptJSON,
  videoSnipptJSON,
  eventSnipptJSON,
} from "../../components/Seo/mlpSeoData";
import { pageOne } from "../../components/PressReleases/data";

import "./styles.scss";

const PressReleases = () => {
  return (
    <React.Fragment>
      <Seo
        title="Press Releases for Kraftshala"
        description="Read the various articles published by famous media publications about Kraftshala to form your opinion. Check out our online courses today or hire a certified digital marketing expert"
        url="https://www.kraftshala.com/marketing-launchpad/digital-marketing-course/"
        courseSnipptJSON={courseSnipptJSON}
        faqSnipptJSON={faqSnipptJSON}
        videoSnipptJSON={videoSnipptJSON}
        eventSnipptJSON={eventSnipptJSON}
      />

      <div className="main-PressReleases-page">
        <Layout>
          <h1 className="page-header">Press Releases</h1>
          <p className="page-description">
						Kraftshala makes India’s most loved marketing and sales courses. <br /> See what's being
						talked about us.
          </p>
          <div className="page-content inner-container">
            {pageOne.map((data) => {
              return (
                <PressRelease
                  key={data.id}
                  thumbnail={data.thumbnail}
                  title={data.title}
                  about={data.about}
                  type={data.type}
                  link={data.link}
                />
              );
            })}
          </div>

          <div className="pagination inner-container">
            <Link to="/press-releases">
              <div className="number active">1</div>
            </Link>
            {/* <Link to="/blog/page/2">
							<div className="number">2</div>
						</Link>
						<Link to="/blog/page/2">
							<div className=" next">Next {">"}</div>
						</Link> */}
          </div>
        </Layout>
      </div>
    </React.Fragment>
  );
};

export default PressReleases;
